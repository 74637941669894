import {graphql, useStaticQuery} from 'gatsby';

export const usePrivacy = () => {
  const {allPrivacyJson} = useStaticQuery(graphql`
    query Privacy {
      allPrivacyJson {
        edges {
          node {
            title
            description
            category
          }
        }
      }
    }
  `);

  return allPrivacyJson.edges;
};
