import React from 'react';

// Layout
import Layout from '../containers/layout/Layout';

// Components
import TextWidget from '../components/common/text-widget/TextWidget';

// Data
import {usePrivacy} from '../graphql/privacy/privacy';

const Privacy = () => {
  const privacyData = usePrivacy();

  return (
    <Layout>
      <h2 style={{marginTop: '16px', marginBottom: '19px'}}>
        PRIVACY POLICY AND NOTICE
      </h2>
      <TextWidget data={privacyData} className="text-widget--privacy" />
    </Layout>
  );
};

export default Privacy;
